export default `<template>
  <div class='item'>Component1</div>
</template>

<script>
module.exports = {
  name: 'Component'
}
</script>

<style lang="scss">
.item {
    color: red
}
</style>
`
