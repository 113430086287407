"use strict"
const beautify = require("js-beautify")

const templateReg = /(<template\s*?(?:lang=["']\s*pug\s*['"])?\s*>)|(<\/template>)(?=[\s]*<script>)/gi
const scriptReg = /<(?:\/)?script[\s\S]*?(?:lang="\s*(.*)\s*")?\s*>/gi
const styleReg = /<(?:\/)?style[\s\S]*?(?:lang="\s*(.*)\s*")?\s*(?:scoped)?\s*>/gi

const config = {
	indent_size: "2",
	indent_char: " ",
	max_preserve_newlines: "5",
	preserve_newlines: true,
	keep_array_indentation: false,
	break_chained_methods: false,
	indent_scripts: "keep",
	brace_style: "collapse",
	space_before_conditional: true,
	unescape_strings: false,
	jslint_happy: false,
	end_with_newline: false,
	wrap_line_length: "0",
	indent_inner_html: true,
	comma_first: false,
	e4x: false,
	indent_empty_lines: false
}

function getCode(code, block, expReg) {
	let split
	// Encuentra las etiquetas templete,script,style
	let match = code.match(expReg)
	if (!match) {
		return null
	}
	if (!/src/.test(match)) {
		if (block === "template") {
			split = code.split(expReg, 6)
			if (split.length > 0) {
				return match[0] + "\n" + beautify.html(split[3], config) + "\n" + match[1]
			}
		} else if (block === "style") {
			split = code.split(expReg, 4)
			if (typeof split[1] === "undefined" || split[1] === "scss" || split[1] === "less") {
				return match[0] + "\n" + beautify.css(split[2], config) + "\n" + match[1]
			}
		} else {
			split = code.split(expReg, 4)
			if (typeof split[1] === "undefined" || split[1] === "TypeScript") {
				return match[0] + "\n" + beautify(split[2], config) + "\n" + match[1]
			}
		}
	}
	return match[0] + split[2] + match[1]
}

export default function (text) {
	if (!text) {
		return ""
	} else {
		let html = getCode(text, "template", templateReg)
		let script = getCode(text, "script", scriptReg)
		let style = getCode(text, "style", styleReg)
		return (html ? html + "\n" : "") + (script ? "\n" + script + "\n" : "") + (style ? "\n" + style + "\n" : "")
	}
}
